//libraries
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Ripples from "react-ripples";

//components
import { OfferCard } from "../components/OfferCard";
import { OfferTopics } from "../components/OfferTopics";

//css
import '../styles/OfferCard.css';
import '../styles/OfferTopics.css';
import { useEffect, useState } from "react";


export function Home({user, activeUserIdArr, offers, setOffers, departments, setDepartments, topics, setTopics, selectedDeptId, setSelectedDeptId, selectedTopicId, setSelectedTopicId, chatBoxes, setChatBoxes, showTopicsAll, setShowTopicsAll, profileImgHash, offerImgHash, renderActiveStatus, dateNow, classes, selectedClassId, setSelectedClassId, isOffersLoaded})
{
      const navigate=useNavigate();


      useEffect(()=>
      {
            updateDeptsList(selectedClassId);
            updateTopicsList(selectedDeptId);
            setSelectedTopicId(selectedTopicId);
      },
      []);


      async function updateDeptsList(id_classes)
      {
            setSelectedClassId(id_classes);
            setSelectedDeptId(0);
            setSelectedTopicId(0);

            const res=await axios.post('/updateDeptsList', {id_classes});
            let departments=res.data.departments;
            if(departments===false) return setDepartments([]);
            setDepartments(departments);
      };


      async function updateTopicsList(id_departments)
      {
            setSelectedDeptId(id_departments);
            setSelectedTopicId(0);

            const res=await axios.post('/updateTopicsList', {id_departments});
            let topics=res.data.topics;
            if(topics===false) return setTopics([]);
            setTopics(topics);
      };


      function toggleShowTopicsAll()
      {
            setShowTopicsAll(! showTopicsAll);
      };


      function toggleShowTopics(id_offers)
      {
            let offersClone=JSON.parse(JSON.stringify(offers));

            for(let i=0; i<=offersClone.length-1; i++)
            {
                  let offer=offersClone[i];

                  if(offer.id_offers===id_offers)
                  {
                        offer.showTopics=!offer.showTopics;
                        break;
                  };
            };

            setOffers(offersClone);
      };


      return<>
            <div className="container-fluid mb-5">
                  <h2 className="text-center my-4">Choose an offer</h2>

                  <div>
                        <select value={selectedClassId} onChange={e=>updateDeptsList(e.target.value)} className="custom-select text-center border-0 rounded-lg mt-3 mr-sm-4" style={{maxWidth: '390px'}}>
                              <option value={0}>--- Select Class ---</option>
                              {classes.map((class_, i)=>
                                    <option value={class_.id_classes} key={class_.id_classes}>{class_.className}</option>
                              )}
                        </select>

                        { (selectedClassId!=0) &&
                              <select value={selectedDeptId} onChange={e=>updateTopicsList(e.target.value)} className="custom-select text-center border-0 rounded-lg mt-3 mr-sm-4" style={{maxWidth: '390px'}}>
                                    <option value={0}>--- Select Subject ---</option>
                                    {departments.map((dept, i)=>
                                          <option value={dept.id_departments} key={dept.id_departments}>{dept.deptName}</option>
                                    )}
                              </select>
                        }

                        { (selectedDeptId!=0) &&
                              <select value={selectedTopicId} onChange={e=>setSelectedTopicId(e.target.value)} className="custom-select text-center border-0 rounded-lg mt-3" style={{maxWidth: '390px'}}>
                                    <option value={0}>--- Select Topic ---</option>
                                    {topics.map((topic, i)=>
                                          <option value={topic.id_topics} key={topic.id_topics}>{topic.topicName}</option>
                                    )}
                              </select>
                        }
                  </div>


                  <div className="d-flex justify-content-end my-3">
                        <Ripples className="rounded">
                              <div className="bg-white px-2 py-1 c-pointer" onClick={toggleShowTopicsAll}>
                                    <span>show topics </span>
                                    <i className={ 'fa-regular fa-check' + (showTopicsAll ? '' : ' invisible') }></i>
                              </div>
                        </Ripples>
                  </div>


                  { (isOffersLoaded===true) ?
                        (offers.length>0) ?
                              <div className="row d-flex justify-content-around">
                                    {offers.map(offer=><>
                                          <div key={offer.id_offers} className="card-home-box d-flex justify-content-center mb-sm-5 mb-3      col-xl-3 col-lg-4 col-md-5 col-sm-5">
                                                <div className="cardAndDetailsBox">
                                                      <OfferCard user={user} offer={offer} activeUserIdArr={activeUserIdArr} chatBoxes={chatBoxes} setChatBoxes={setChatBoxes} toggleShowTopics={toggleShowTopics} profileImgHash={profileImgHash} offerImgHash={offerImgHash} renderActiveStatus={renderActiveStatus} dateNow={dateNow} />
                                                      { offer.showTopics && <OfferTopics offer={offer} selectedTopicId={selectedTopicId} /> }
                                                </div>
                                          </div>
                                    </>)}
                              </div>
                        :
                              <p className="text-center text-muted mt-5 pt-5">[No offers available]</p>
                  :
                        <div className="text-center mt-5">
                              <i className="fa-solid fa-spinner-third fa-spin fa-2xl     mt-5" />
                        </div>
                  }

            </div>
      </>
};
