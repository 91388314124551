//libraries
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faToggleOn, faToggleOff, faCircle, faUserPen, faRightFromBracket, faAddressCard} from '@fortawesome/free-solid-svg-icons'
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';

//css
import '../styles/Profile.css';

//js
import {navbarColorLearner, navbarColorDarkLearner, navbarColorTeacher, navbarColorDarkTeacher} from '../assets/navbarColors';
import Ripples from 'react-ripples';
import { env } from '../assets/env';


export function Profile({user, setUser, socket, setSocket, profileImgHash, deviceType})
{
      const navigate=useNavigate();


      function getNavbarBgColor()
      {
            if(user.teacherMode==='on') return navbarColorTeacher;
            else return navbarColorLearner;
      };

      function getSelectedNavItemBgColor()
      {
            if(user.teacherMode==='on') return navbarColorDarkTeacher;
            else return navbarColorDarkLearner;
      };

      async function toggleTeacherMode()
	{
		if(user===false) return alert('Please login to access these features');

            return alert('Feature locked. Please send message to our fb page \"MyXAcademy\" to change mode.');

		// let userClone=JSON.parse(JSON.stringify(user));

		// if(userClone.teacherMode==='off') var setTeacherModeTo='on';
            // else var setTeacherModeTo='off';

            // //updaing in backend
            // let res=await axios.post('/toggleTeacherMode', {setTeacherModeTo});

            // //updating in frontend after getting confirmation from backend
            // if(res.data.isDone)
            // {
            //       userClone.teacherMode=setTeacherModeTo;
            //       setUser(userClone);
            // };
	};

      async function logout()
      {
            let res=await axios.post('/logout', {deviceType});

            if(res.data.willLogout===true)
            {
                  setUser(false);
                  if(socket!==false) socket.disconnect();
                  setSocket(false);

                  navigate('/login');
            };
      };


      return<>
            <div className="d-flex justify-content-center rounded-bottom pt-3" style={{backgroundColor: getNavbarBgColor(), marginBottom: '-20px', paddingBottom: '40px'}}>
                  <div className="d-flex userNameImgBox">

                        <img className='rounded-circle ml-3' src={`${env.backendBaseUrl}/profileImages/${user.id_users}.jpg?${profileImgHash}`} onError={(e)=>e.target.src=require('../assets/defaultImage.jpg')} style={{height: '60px', width: '60px', objectFit: 'cover'}} />

                        <div className="text-dark d-flex align-items-center ml-2">
                              <div className="req_username font-weight-bold">{user===false ? 'Guest' : user.username}</div>
                        </div>

                  </div>
            </div>

            <div className="container p-0 optionsBox">

                  <Ripples className="d-flex justify-content-between mb-5 bg-white py-2 px-3 rounded mx-3 shadow-sm" onClick={toggleTeacherMode}>
                        <div>Teacher mode</div>
                        {user.teacherMode==='on' ?
                              <div className='text-success'><FontAwesomeIcon icon={faToggleOn} size='xl' /></div>
                        :
                              <div className='text-dark'><FontAwesomeIcon icon={faToggleOff} size='xl' /></div>
                        }
                  </Ripples>


                  <div>

                        <Ripples onClick={()=>navigate('/editProfile')} className="menuItem mb-2 bg-white py-3 px-3 d-flex justify-content-between rounded">
                              <div><FontAwesomeIcon icon={faUserPen} className='mr-2 lightAsh' /> Edit profile</div>
                              <div className="lightAsh"><b>{'>'}</b></div>
                        </Ripples>

                        { (user.teacherMode==='on') &&
                              <Ripples onClick={()=>navigate('/manageOffers')} className="menuItem mb-2 bg-white py-3 px-3 d-flex justify-content-between rounded">
                                    <div><i className="fa-solid fa-address-card lightAsh"></i> <span className='ml-2'>Manage offers</span></div>
                                    <div className="lightAsh"><b>{'>'}</b></div>
                              </Ripples>
                        }

                        { (user===false) ?
                              <div className='mb-5' style={{marginTop: '5rem'}}>
                                    <Ripples onClick={()=>navigate('/login')} className="bg-white py-3 px-3 d-flex justify-content-between rounded">
                                          <div className='text-success'>
                                                <i className="fa-solid fa-right-to-bracket"></i>
                                                <span className='ml-2'>Login</span>
                                          </div>
                                    </Ripples>
                              </div>
                        :
                              <div className='mb-5' style={{marginTop: '5rem'}}>
                                    <Ripples onClick={logout} className="bg-white py-3 px-3 d-flex justify-content-between rounded">
                                          <div className='text-danger'>
                                                <i className="fa-solid fa-right-from-bracket"></i>
                                                <span className='ml-2'>Logout</span>
                                          </div>
                                    </Ripples>
                              </div>
                        }

                  </div>

            </div>

      </>
};
